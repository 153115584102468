import NavBar from '../components/NavBar';
import { makeStyles } from '@material-ui/core/styles';
import { BACKGROUND_COLOR, THEME_PRIMARY_COLOR } from '../constants';
import ControlYourFuture from '../img/ControlYourFuture.png';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Free from '../img/free.png';
import Secure from '../img/secure.png';
import MadeForYou from '../img/madeforyou.png';

const useStyles = makeStyles(theme => ({
    container: {
        overflowY: 'auto'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title: {
        textAlign: 'left',
        fontSize: '4rem',
        letterSpacing: '10px'
    },
    titleContainer: {
        paddingLeft: '2rem',
    },
    menuItemContainer: {
        color: THEME_PRIMARY_COLOR,
        textDecoration: 'none'
    },
    grow: {
        flex: 1
    },
    activeMenuItemStyle: {
        fontWeight: 'bold',
        borderBottom: 'solid',
        borderBottomWidth: '3px'
    },
    topRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        overflow: 'hidden',
        maxHeight: '500px',
        padding: '2rem'
    },
    controlYourFutureImg: {
        width: '400px',
        maxHeight: '400px',
    },
    tryNowButton: {
        color: '#212121',
        textDecoration: 'none',
        paddingRight: "5px",
        paddingLeft: "5px",
        border: '2px solid',
        padding: '0.5rem',
        borderRadius: '0.25rem'
    },
    introContainer: {
        background: BACKGROUND_COLOR,
        height: '100%',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    filioIs: {
        color: THEME_PRIMARY_COLOR,
        fontSize: '3rem',
        fontWeight: 300
    },
    valuesContainer: {
        display: 'flex',
        paddingTop: '2rem',
        maxWidth: '1440px'
    },
    valueName: {
        fontSize: '2rem',
        fontWeight: 300,
        color: THEME_PRIMARY_COLOR,
    },
    descriptionContainer: {
        paddingLeft: '2rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
    },
    descriptionContainerLeft: {
        paddingRight: '2rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
    },
    description: {
        color: THEME_PRIMARY_COLOR,
        lineHeight: '2rem'
    },
    copyright: {
        display: 'flex',
        justifyContent: 'center'
    }
  }));

export default function Home() {
    const classes = useStyles();
    const isUserLoggedIn = useSelector(state => state.user.is_logged_in);

    const tryNowButton = () => isUserLoggedIn ? 
        <Button color="inherit">Dashboard</Button> :
        <Button color="inherit">Try for free</Button>;
    return (
        <div className={classes.container}>
            <NavBar />
            <div className={classes.topRow}>
                <div className={classes.titleContainer}>
                    <h1 width={1} className={classes.title}>Control your <br/>future,<br/>control your <br/>spend.</h1>
                    <NavLink className={classes.tryNowButton} to="/upload">{tryNowButton()}</NavLink>
                </div>
                <img
                    alt="control-your-future-img"
                    width="50%"
                    className={classes.controlYourFutureImg}
                    src={ControlYourFuture}></img>
            </div>
            <div className={classes.introContainer}>
                <h2 className={classes.filioIs}>Filio is</h2>
                <div className={classes.valuesContainer}>
                    <img maxHeight={'400px'} width={'300px'} src={Free}></img>
                    <div className={classes.descriptionContainer}>
                        <p className={classes.valueName}>Free</p>    
                        <p className={classes.description}>
                            With the mission to help you save more for the future, we’ve decided to offer
                            Filio’s spend management services for free of charge. Filio operates solely on 
                            your willingess to use our platform and trusting us in helping brighten your future.
                        </p>
                    </div>
                </div>
                <div className={classes.valuesContainer}>
                    <div className={classes.descriptionContainerLeft}>
                        <p className={classes.valueName}>Secure</p>    
                        <p className={classes.description}>
                        At Filio, security of the data entrusted to us by our customers is out utmost priority.
                        Access to all the data on the Filio systems is limited to you. Through strict guidelines
                        to keep your data safe, we look forward to gaining your trust in our spend
                        management solution.
                        </p>
                    </div>
                    <img paddingLeft={'2rem'} maxHeight={'400px'} width={'300px'} src={Secure}></img>
                </div>
                <div className={classes.valuesContainer}>
                <img paddingLeft={'2rem'} maxHeight={'400px'} width={'300px'} src={MadeForYou}></img>
                    <div className={classes.descriptionContainer}>
                        <p className={classes.valueName}>Made for you</p>    
                        <p className={classes.description}>
                        Our main goal is to help you save and budget for a brighter future by allowing you to
                        see where your money is going. Our mission revolves around you. We’d love to know
                        that our product has helped you better your family’s future.
                        Please contact us at <b>support@filioplan.com</b>. We’d love to help!
                        </p>
                    </div>
                </div>
            </div>
            <p className={classes.copyright}>&copy; 2022, Filio. All rights reserved</p>
        </div>
    );
}