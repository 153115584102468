import { combineReducers } from "redux";
import DashboardReducer from './DashboardReducer';
import AccountsReducer from './AccountsReducer';
import UserReducer from './UserReducer';

export default combineReducers({ 
    dashboard: DashboardReducer,
    accounts: AccountsReducer,
    user: UserReducer
});
