import createAPICall from '../../utils/ApiService';
import { API_HOST } from '../../constants';

export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_CATEGORY_FOR_TABLE = 'SET_CATEGORY_FOR_TABLE';

export const setDashboardData = (data) => ({
    type: SET_DASHBOARD_DATA,
    payload: {
        data
    }
});

export const setCategoryForTable = (selectedCategory) => ({
  type: SET_CATEGORY_FOR_TABLE,
  payload: {
    selectedCategory
  }
});

export const fetchDashboardData = () => createAPICall({
    url: `${API_HOST}/api/filio/dashboard`,
    method: 'GET',
    onSuccess: (dispatch, getState,response) => {dispatch(setDashboardData(response))}
});
