import {connect} from 'react-redux';
import { useEffect } from 'react';
import { fetchDashboardData, setCategoryForTable } from '../redux/actions/DashboardActions';
import {
    PieChart, Pie, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, AreaChart, Area, Cell, Label
  } from 'recharts';
import moment from 'moment';
import BasicDataTable from './BasicDataTable';
import Expandable from './Expandable';
import BasicCard from './BasicCard';
import {Tabs, Tab} from '@material-ui/core';
import { SIMPLE_DATE_FORMAT, OVERLAY_Z_INDEX } from '../constants';
import { getTopFiveCategories } from '../utils/DashboardDataUtil';

const styles = {
    gridContainer: {
      display: "grid",
      width: '100%',
      gridTemplateColumns: "repeat(6, minmax(calc(100% / 6)))",
      gridTemplateRows: "repeat(6, calc(100vh / 6))",
      columnGap: 24,
      // rowGap: "24px",
      'justify-items': "stretch",
      alignItems: "stretch",
      gridAutoFlow: "column",
      justifyContent: "stretch",
      paddingTop: 24,
      rowGap: 24
    },
    graphsContainer: {
        display: "flex",
        // padding: 20,
        height: "calc(100vh / 3)",
        alignItems: 'center',
        'grid-row': "1 / 3",
        "grid-column": "1 / 7",
        maxHeight: '100%'
    },
    toolTipWrapperStyle: {
      zIndex: OVERLAY_Z_INDEX
    },
    col3: {
      "grid-column": "1 / 4"
    },
    col4_6: {
      "grid-column": "4 / 7"
    },
    col6: {
      "grid-column": "1 / 7"
    },
    row3: {
      "grid-row": "3 / 5"
    },
    row5: {
      "grid-row": "5"
    },
    fullWidth: {
      width: '100%'
    }
};

const callWithRefresh = (action) => {
    action();
    setTimeout(() => callWithRefresh(action), 50000);
}

const ALLOWED_TRANSACTION_TYPES = ["Sale", "Return"]; // Exclude payments

const getBarChartForTransactionsByMonth = (data, heading) => {
    return (data && data.length > 0 &&
    <BasicCard title={heading} height={"100%"} width={"70%"} margin="0px 24px 0px 0px">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart barGap="2%" data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis dataKey="last_year" label={{value: "amount ($)", position: 'insideLeft', angle: 90 }} />
          <Tooltip wrapperStyle={styles.toolTipWrapperStyle}/>
          <Bar maxBarSize={25} dataKey="last_year" fill="#389c75" />
          <Bar maxBarSize={25} dataKey="this_year" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </BasicCard>);
}

const getCategorizedPieChart = (data, heading) => {
    const total = data.reduce((totalAmt,t) => totalAmt + t.amount, 0).toFixed(2);

    return (data && data.length > 0 &&
      <BasicCard title={heading}  height={"100%"} width={"30%"}>
        <ResponsiveContainer height="100%" width="100%">
            <PieChart>
                <Pie
                    data={data}
                    dataKey="amount"
                    nameKey="category"
                    innerRadius={50}
                    outerRadius={80}
                    >
                    <Label value={`$${total}`} position="center" />
                    {
          	           data.map((entry) => <Cell fill={entry.color}/>)
                    }
                </Pie>
                <Tooltip active/>
            </PieChart>
        </ResponsiveContainer>
      </BasicCard>
    );
}

const getExpensesByCategoryDashboard = (expensesByCategory, heading) => {
  if (!expensesByCategory || !expensesByCategory.data) return [];
  const {data} = expensesByCategory;
  const dataArr = Object.values(data);

  const getCategorizedLines = () => (
      expensesByCategory.categories.map(category =>
          <Area type="monotone" dataKey={category.name} stroke={category.color} fill={`url(#color-${category.name.replace(" ", "-").replace("& ", "")})`} />
      )
  );
  const getCategorizedLinearGradients = () => (
        expensesByCategory.categories.map(category =>
          <linearGradient id={`color-${category.name.replace(" ", "-").replace("& ", "")}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={category.color} stopOpacity={0.8}/>
            <stop offset="95%" stopColor={category.color} stopOpacity={0}/>
          </linearGradient>
        )
  );

  return (
    <BasicCard title={heading} width="100%">
      <ResponsiveContainer height="100%" width="100%">
          <AreaChart data={dataArr}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                {getCategorizedLinearGradients()}
              </defs>
              <XAxis dataKey="month" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip wrapperStyle={styles.toolTipWrapperStyle} itemSorter={(item) => -1*item.value} />
              {getCategorizedLines()}
          </AreaChart>
      </ResponsiveContainer>
    </BasicCard>
  );
}

const Dashboard = ({ dashboardData, fetchUserTransactionsData, onHandleTabChange }) => {
    useEffect(() => {
        if (!dashboardData) {
            callWithRefresh(fetchUserTransactionsData);
        }
    });
    const {
      transactions,
      selectedCategory,
      aggregatedByCategory,
      categorizedByMonthLastYear,
      categorizedByMonthCurrentYear,
      monthlyAggregated,
      top5Categories,
      simplifiedForCategories
    } = dashboardData || {};

    if (!transactions || transactions.length === 0) {
        return null;
    }

    const category = selectedCategory || (top5Categories || [])[0];
    const categoryGraphs = getExpensesByCategoryDashboard(categorizedByMonthLastYear, "Expense by category - previous year");
    const categoryGraphsCurrentYear = getExpensesByCategoryDashboard(categorizedByMonthCurrentYear, "Expense by category - this year");
    const total = (aggregatedByCategory.reduce((totalAmt,t) => totalAmt + t.amount, 0) / 12).toFixed(2);

    return (
      <>
        <div style={styles.gridContainer}>
            <div style={styles.graphsContainer}>
              {getBarChartForTransactionsByMonth(monthlyAggregated, `Month over month spend (avg. monthly spend $${total})`)}
              {getCategorizedPieChart(aggregatedByCategory, "Historical spend by category")}
            </div>
            <div style={{...styles.col3, ...styles.row3}}>
              {categoryGraphs}
            </div>
            <div style={{...styles.col4_6, ...styles.row3}}>
              {categoryGraphsCurrentYear}
            </div>
            {simplifiedForCategories[category] && simplifiedForCategories[category].length &&
            <div style={{...styles.col6, ...styles.row5}}>
              <Expandable heading={`Expenses from ${(category||"").toLowerCase()}`} expanded={true} >
                  <div style={styles.fullWidth}>
                    <Tabs value={category} defaultValue={0} onChange={onHandleTabChange}>
                      {top5Categories.map(category => <Tab value={category} label={category} />)}
                    </Tabs>
                    <BasicDataTable headings={['Description', 'Amount Paid','Date']} data={simplifiedForCategories[category]} />
                  </div>
              </Expandable>
            </div>
            }
        </div>
      </>
    );
}

const mapStateToProps = (state) => ({
    dashboardData: state.dashboard
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserTransactionsData: () => dispatch(fetchDashboardData()),
    onHandleTabChange: (event, category) => dispatch(setCategoryForTable(category)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
