import { SET_ACCOUNTS_DATA } from '../actions/AccountActions';

const initial_state = null;

// eslint-disable-next-line
export default function(state = initial_state, action) {
    switch(action.type) {
        case SET_ACCOUNTS_DATA:
            const { accounts } = action.payload && action.payload.data;
            return {
                ...state,
                accounts
            };

        default:
            return state;
    }
}
