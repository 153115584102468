import createAPICall from '../../utils/ApiService';
import { API_HOST } from '../../constants';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_AFTER_LOGOUT = 'SET_USER_DATA_AFTER_LOGOUT';

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: {
        data
    }
});

export const setUserDataAfterLogout = (data) => ({
    type: SET_USER_DATA_AFTER_LOGOUT,
    payload: {
        data
    }
});

export const fetchUserData = () => createAPICall({
    url: `${API_HOST}/api/filio/userdata`,
    method: 'GET',
    onSuccess: (dispatch, getState,response) => {
        dispatch(setUserData({...response, is_fetched: true}));
    },
    onError: (dispatch, getState, response) => {
        dispatch(setUserData({is_fetched: true}));
    }
});

export const doLogout = () => {
    return createAPICall({
    url: `${API_HOST}/api/filio/logout`,
    method: 'POST',
    onSuccess: (dispatch, getState,response) => {
        dispatch(setUserData({is_logged_in: false}));
        window.location.assign('/');
    }
})};