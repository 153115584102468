import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 48px)',
    padding: 24
  }
});

export default function BasicCard({children, title="", height="100%", width, margin=0}) {
  const classes = useStyles();
  const styleProps = {margin: margin, width: width, height: height, maxHeight: '100%', maxWidth: '100%'};

  return (
    <div style={width ? {...styleProps, width} : styleProps}>
      <Paper classes={classes} square elevation={1}>
       {title && <h4 style={{margin: "0px 0px 24px 0px"}}>{title}</h4>}
        {children}
      </Paper>
    </div>
  );
}
