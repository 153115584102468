import { SET_USER_DATA, SET_USER_DATA_AFTER_LOGOUT} from '../actions/UserActions';

const initial_state = {
    is_logged_in: false,
    is_fetched: false
};

// eslint-disable-next-line
export default function(state = initial_state, action) {
    switch(action.type) {
        case SET_USER_DATA:
            const { enrollment_id, user_first_name, user_last_name, is_logged_in } = action.payload && action.payload.data;
            
            return {
                ...state,
                user_first_name,
                user_last_name,
                enrollment_id,
                is_logged_in,
                is_fetched: true
            };

        case SET_USER_DATA_AFTER_LOGOUT:
            return { ...initial_state, is_fetched: true };

        default:
            return state;
    }
}
