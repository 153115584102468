import createAPICall from '../../utils/ApiService';
import { API_HOST } from '../../constants';

export const SET_ACCOUNTS_DATA = 'SET_ACCOUNTS_DATA';

export const setAccountsData = (data) => ({
    type: SET_ACCOUNTS_DATA,
    payload: {
        data
    }
});

export const fetchAccountsData = () => createAPICall({
    url: `${API_HOST}/api/filio/accounts`,
    method: 'GET',
    onSuccess: (dispatch, getState,response) => {dispatch(setAccountsData(response))}
});

export const removeAccount = (accountId) => createAPICall({
    url: `${API_HOST}/api/filio/account/${accountId}`,
    method: 'DELETE',
    onSuccess: (dispatch, getState, response) => {
        dispatch(fetchAccountsData());
    }
});
