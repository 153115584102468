import React, { useCallback, useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import BasicCard from '../components/BasicCard';
import {primaryStyles} from '../styles/CommonStyles';
import {
    usePlaidLink,
} from 'react-plaid-link';
import { API_HOST } from '../constants';
import { Button, makeStyles } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import { fetchAccountsData, removeAccount } from '../redux/actions/AccountActions';

const createTokenURI = `${API_HOST}/api/filio/create-link-token`;
const setAccessTokenAPI = `${API_HOST}/api/filio/exchange-public-token`;

const accountsStyles = makeStyles(theme => ({
    userSummaryContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    fontWeightBold: {
        fontWeight: 600
    },
    heading: {
        marginLeft: 24
    }
}));

function Accounts({accountsData, fetchAccountsDataAction, removeAccountAction}) {
    const [linkToken, setLinkToken] = useState(null);
    const userInfo = useSelector(state => state.user);
    const generateToken = async () => {
        const response = await fetch(createTokenURI, {
            method: 'POST',
            credentials: 'include'
        });
        const data = await response.json();
        console.log({data})
        setLinkToken(data.link_token);
    };
    useEffect(() => {
        if (!accountsData) {
            fetchAccountsDataAction();
        }
    }, []);

    const onSuccess = useCallback(
        async (public_token, metadata) => {
            // log and save metadata
            // exchange public token
            const response = await fetch(setAccessTokenAPI, {
                body: JSON.stringify({ public_token }),
            });
            fetchAccountsDataAction();
        },
        [],
    );

    const onExit = useCallback(
        (error, metadata) => {
            console.log({error})
            console.log({metadata})
            // log and save error and metadata
            // handle invalid link token
            if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
            // generate new link token
            }
            // to handle other error codes, see https://plaid.com/docs/errors/
        },
        [],
    );

    const onEvent = useCallback(
        (
            eventName,
            metadata,
        ) => {
            // log eventName and metadata
        },
        [],
    );
    // The usePlaidLink hook manages Plaid Link creation
    // It does not return a destroy function;
    // instead, on unmount it automatically destroys the Link instance
    console.log({linkToken})
    const config = {
        onSuccess,
        onExit,
        onEvent,
        token: linkToken,
        //required for OAuth; if not using OAuth, set to null or omit:
        // receivedRedirectUri: window.location.href,
        env: "development"
    };
    const { open, exit, ready } = usePlaidLink(config);
    const handleLinkOpen = useCallback(() => {
        if (ready) {
            open();
        }
    });
    
    const commonStyles = primaryStyles();
    const accountsStyle = accountsStyles();
    const { accounts } = accountsData || {};

    return (
        <div>
            <NavBar />
            <BasicCard margin="24px 24px 0px 24px">
                <div className={accountsStyle.userSummaryContainer}>
                    <div>
                    <p className={accountsStyle.fontWeightBold}>First name</p>
                    <p>{userInfo.user_first_name}</p>
                    </div>
                    <div>
                    <p className={accountsStyle.fontWeightBold}>Last name</p>
                    <p>{userInfo.user_last_name}</p>
                    </div>
                    <Button onClick={handleLinkOpen} className={[commonStyles.filioButton, commonStyles.fitContent]}>Add an account</Button>
                </div>
            </BasicCard>
            
            <h2 className={accountsStyle.heading}>Accounts</h2>
            <>
            {(accounts || []).map(account =>
                (
                    <BasicCard margin="24px 24px 0px 24px">
                        <div className={accountsStyle.userSummaryContainer}>
                            <div>
                                <p className={accountsStyle.fontWeightBold}>
                                    Account
                                </p>
                                <p className={commonStyles.marginBottomNone}>{account.name}</p>
                                <p className={commonStyles.subTextColor}>{account.official_name}</p>
                            </div>
                            <div>
                                <p className={accountsStyle.fontWeightBold}>Type</p>
                                <p className={commonStyles.marginBottomNone}>{account.subtype}</p>
                            </div>
                            <Button onClick={() => removeAccountAction(account.account_id)}>
                                Remove
                            </Button>
                        </div>
                    </BasicCard>
                ))}
            </>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accountsData: state.accounts
});

const mapDispatchToProps = (dispatch) => ({
    fetchAccountsDataAction: () => dispatch(fetchAccountsData()),
    removeAccountAction: (accountId) => dispatch(removeAccount(accountId))
});
export default connect(mapStateToProps, mapDispatchToProps)(Accounts);