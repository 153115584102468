import { makeStyles } from '@material-ui/core/styles';
import { THEME_SECONDARY_COLOR, THEME_PRIMARY_COLOR } from '../constants';

export const primaryStyles = makeStyles(theme => ({
        filioButton: {
            backgroundColor: THEME_SECONDARY_COLOR,
            color: THEME_PRIMARY_COLOR,
            borderRadius: 0
        },
        uploadButton: {
            marginRight: "auto",
            marginLeft: "auto",
            display: "block"
        },
        fitContent: {
            width: "fit-content",
            height: "fit-content",
            padding: "10px"
        },
        subTextColor: {
            color: "#616161",
            marginTop: 5
        },
        marginBottomNone: {
            marginBottom: 0
        },
        upload: {
            float: 'right',
            width: '100%',
            padding: 20
        }
    })
);

export const pageStyles = makeStyles(theme => ({
    page: {
        marginLeft: 24,
        marginRight: 24,
        marginTop: 24
    }
}));
