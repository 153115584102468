import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { THEME_SECONDARY_COLOR, THEME_PRIMARY_COLOR } from './constants';
import Routes from './routes';
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";

import './App.css';
import { fetchUserData } from './redux/actions/UserActions';
import { fetchAccountsData } from './redux/actions/AccountActions';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: THEME_PRIMARY_COLOR,
    },
    secondary: {
      main: THEME_SECONDARY_COLOR,
    },
  },
});

function App() {
  const state = useSelector(state => state);

  useEffect(() => {
    setTimeout(
      () => {
        store.dispatch(fetchUserData());
        store.dispatch(fetchAccountsData());
      },
      200
    );
  }, []);

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            {Routes(state.user).map(route => 
              <Route
                key={route.page}
                path={route.path}
                component={route.page} />
            )}
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
