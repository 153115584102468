import { useState } from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Snackbar, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { API_HOST } from '../constants';
import { primaryStyles } from '../styles/CommonStyles';

//TODO: Temporarily redirect to poolapp
const fileUploadAPI = `${API_HOST}/api/filio/upload/statements`;

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: "10px"
    },
    fileUploadContainer: {
        display: 'block',
        padding: 20
    },
    textField: {
        width: '100%'
    }
}));

export default function FileUpload() {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [uploadName, setUploadName] = useState(null);
    const [key, setKey] = useState(0); // Used to clear dropzone
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const buttonClasses = primaryStyles();

    const handleChange = (files) => {
        setFiles(files);
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('statement', files[0]);
        formData.append('uploadName', uploadName);
        fetch(fileUploadAPI, {
            method: 'PUT',
            body: formData,
            credentials: "include"
        })
        .then(() => {
            setShowSuccessMessage(true);
            handleChange([]);
            setKey(key + 1);
        });
    }

    const handleClose = () => {
        setShowSuccessMessage(false);
    }

    return (
        <div className={classes.fileUploadContainer}>
            <div className={classes.marginTop}/>
            <TextField className={[classes.textField]}
                required
                id="outlined-basic"
                label="Upload Name"
                variant="outlined"
                value={uploadName}
                onChange={(event) => {
                    setUploadName(event.target.value);
                }} />
            <DropzoneArea filesLimit={1} key={key} onChange={handleChange} />
            <div className={classes.marginTop} />
            <Button onClick={handleUpload} className={[buttonClasses.filioButton, buttonClasses.uploadButton]}>Upload</Button>
            <Snackbar
                key={"upload-snackbar"}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showSuccessMessage}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    File uploaded succesfully.
                </Alert>
            </Snackbar>
        </div>
    );
}