const createAPICall = ({
    url, method, headers, body,
    // eslint-disable-next-line
    onStart: onStart = () => {},
    // eslint-disable-next-line
    onSuccess: onSuccess = () => {},
    // eslint-disable-next-line
    onError: onError = () => {}
  }) => (dispatch, getState) => {
    (async () => {
      try {
        !!onStart && onStart(dispatch, getState);
        const responseData = await fetch(url, {
          method,
          credentials: 'include',
          headers: {
            Accept: 'applicaiton/json',
            'Content-Type': 'application/json',
            ...headers
          },
          ...(method !== 'GET' && method !== 'HEAD' ? {body: JSON.stringify(body || {})} : {})
        });
        let response = {
          status: responseData.status
        };
        if (responseData.ok) {
          response = await responseData.json();
          !!onSuccess && onSuccess(dispatch, getState, response);
          return;
        }
        !!onError && onError(dispatch, getState, response);
      } catch (error) {
        console.log('api_service.js API CALL ERRORED OUT', error);
        !!onError && onError(dispatch, getState, {error});
      }
    })();
  };

export default createAPICall;
