import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    margin: 15
  }
});

export default function BasicDataTable({ data, headings, title }) {
  const classes = useStyles();
  if (!data || data.length === 0) {
      return null;
  }

  return (
    <TableContainer component={Paper}>
    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
    </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headings.map((title, idx) => <TableCell align={idx === 0 ? "left" : "right"}>{title}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.description}>
              <TableCell component="th" scope="row">
                {row.description}
              </TableCell>
              <TableCell align="right">{`$${row.amount}`}</TableCell>
              <TableCell align="right">{row.simple_datetime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
