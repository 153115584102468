import { SET_DASHBOARD_DATA , SET_CATEGORY_FOR_TABLE} from '../actions/DashboardActions';
import { calculateDashboardPayload } from '../../utils/DashboardDataUtil';

const initial_state = null;

// eslint-disable-next-line
export default function(state = initial_state, action) {
    switch(action.type) {
        case SET_DASHBOARD_DATA:
            const { transactions } = action.payload && action.payload.data;
            const dashboardData = calculateDashboardPayload(transactions);
            return {
                ...state,
                transactions,
                ...dashboardData,
            };

        case SET_CATEGORY_FOR_TABLE:
          const {selectedCategory} = action.payload;
          return {
            ...state,
            selectedCategory,
          };

        default:
            return state;
    }
}
