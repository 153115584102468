import React, { useState } from 'react';
import {connect} from 'react-redux';

import FileUpload from "../components/FileUpload";
import NavBar from "../components/NavBar";
import Dashboard from "../components/Dashboard";
import Expandable from "../components/Expandable";
import { primaryStyles } from "../styles/CommonStyles";
import { Dialog, Button, makeStyles } from "@material-ui/core";
import { useEffect } from 'react';

const styles = makeStyles({
    page: {
        marginLeft: 24,
        marginRight: 24
    }
});

const fileUploadModal = ({
    isOpen = false,
    onClose = () => {}
}) => {
    return <Dialog 
                open={isOpen}
                onClose={onClose}
                fullWidth={true}
            >
            <FileUpload />
        </Dialog>;
}

function Upload({ dashboardData }) {
    const classes = styles();
    const commonStyles = primaryStyles();
    const [isOpen, setIsOpen] = useState(!dashboardData);
    const [shouldOpen, setShouldOpen] = useState(false);

    useEffect(() => {
        if (dashboardData && !shouldOpen) {
            setIsOpen(false);
        }
    });
    
    return (
        <>
            <NavBar />
            {!isOpen && <Button className={[commonStyles.filioButton,
                commonStyles.upload]}
                onClick={() => {
                    setIsOpen(true);
                    setShouldOpen(true);
                }}>Upload Statement</Button>}
            {fileUploadModal({
                isOpen,
                onClose: () => {
                    setIsOpen(false);
                    setShouldOpen(false);
                }
            })}
            <div className={classes.page}>
                <div marginTop={5}></div>
                <Dashboard />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    dashboardData: state.dashboard
});

export default connect(mapStateToProps, null)(Upload);