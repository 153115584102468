import Home from './page/Home';
import Upload from './page/Upload';
import Accounts from './page/Accounts';
import { AuthenticatedPage } from './components/AuthenticatedPage';

const routes = (userInfo) => [
    // {
    //     path: "/manage",
    //     page: AuthenticatedPage(Accounts, userInfo),
    // },
    {
        path: "/upload",
        page: Upload,
    },
    {
        path: "/",
        page: Home,
    },
];

export default routes;