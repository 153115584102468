import React from 'react';
import { AppBar, Typography, Button, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import Logo64 from '../img/Logo64.png';
import { useSelector } from 'react-redux';
import GoogleButton from 'react-google-button'
import store from '../redux/store';
import { doLogout } from '../redux/actions/UserActions';
import { API_HOST } from '../constants';

const useStyles = makeStyles(theme => ({
    container: {
      flexGrow: 1,
      height: "100vh"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title: {
        textAlign: 'center',
    },
    menuItemContainer: {
        color: '#212121',
        textDecoration: 'none',
        paddingRight: "5px",
        paddingLeft: "5px"
    },
    grow: {
        flex: 1
    },
    activeMenuItemStyle: {
        fontWeight: 'bold',
        borderBottom: 'solid',
        borderBottomWidth: '3px'
    },
    marginRight: {
        marginRight: '10px'
    },
    appBar: {
        boxShadow: '0px 0px 0px 0px',
        borderBottom: '1px solid #E0E0E0',
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    googleButton: {
        marginLeft: '24px'
    }
  }));

export default function NavBar() {
    const classes = useStyles();

    const accountsButton = () => <Button color="inherit">Manage</Button>;
    const homeButton = () => <Button color="inherit">Home</Button>;
    const isUserLoggedIn = useSelector(state => state.user.is_logged_in);
    const isFetched = useSelector(state => state.user.is_fetched);
    const tryButton = () => <Button color="inherit">Try for free</Button>;
    const dashboardButton = () => <Button color="inherit">Dashboard</Button>;

    const buttonToRender = isUserLoggedIn ? dashboardButton : tryButton;
    
    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                    <img alt="filio-logo" height={24} width={24} src={Logo64}></img>
                    <div className={classes.marginRight} />
                    <Typography variant="h6">
                        Filio
                    </Typography>
                <div className={classes.grow} />
                <NavLink activeClassName={classes.activeMenuItemStyle} className={classes.menuItemContainer} exact to="/">{homeButton()}</NavLink>
                {/* {isUserLoggedIn && <NavLink activeClassName={classes.activeMenuItemStyle} className={classes.menuItemContainer} exact to="/manage">{accountsButton()}</NavLink>} */}
                <NavLink activeClassName={classes.activeMenuItemStyle} className={classes.menuItemContainer} to="/upload">{buttonToRender()}</NavLink>
                {isFetched && (!isUserLoggedIn ? <GoogleButton onClick={() => window.location.assign(`${API_HOST}/api/filio/login/google`)}
                    className={classes.googleButton} /> :
                    <Button onClick={() => store.dispatch(doLogout())}>
                        Logout
                    </Button>)}
            </Toolbar>
        </AppBar>
    );
}
