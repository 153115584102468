import moment from 'moment';
import { SIMPLE_DATE_FORMAT } from '../constants';
const ALLOWED_TRANSACTION_TYPES = ["Sale", "Return"]; // Exclude payments
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getMonthlyAggregated = (transactions, nextYearTransactions) => {
    if (!transactions) {
        return {};
    }

    const saleTransactions = transactions.filter((t) => ALLOWED_TRANSACTION_TYPES.indexOf(t.transaction_type) !== -1);
    const thisYearSaleTransactions = nextYearTransactions.filter((t) => ALLOWED_TRANSACTION_TYPES.indexOf(t.transaction_type) !== -1);

    // Group the transactions by month
    const processedTransactions = saleTransactions.reduce((aggregates, t) => {
        const formattedDate = moment(t.transaction_date, SIMPLE_DATE_FORMAT);
        const monthName = months[formattedDate.month()];

        // const key = `${monthName} - ${formattedDate.year() - era}`;
        const key = monthName;
        aggregates[key] = { this_year: 0, last_year: +(((aggregates[key] || {}).last_year || 0) + t.amount).toFixed(2), date: key };

        return aggregates;
    }, {});

    thisYearSaleTransactions.reduce((processedTransactions, t) => {
        const formattedDate = moment(t.transaction_date, SIMPLE_DATE_FORMAT);
        const monthName = months[formattedDate.month()];

        const key = monthName;
        processedTransactions[key] = { ...processedTransactions[key], this_year: +(((processedTransactions[key] || {}).this_year || 0) + t.amount).toFixed(2) };

        return processedTransactions;
    }, processedTransactions);

    return Object.values(processedTransactions);
};

export const sort = (transactions, isChronological) => {
    const sorted = transactions && transactions.sort((t1,t2) => {
        const d1 = moment(t1.transaction_date, SIMPLE_DATE_FORMAT);
        const d2 = moment(t2.transaction_date, SIMPLE_DATE_FORMAT);

        return isChronological ? d1.diff(d2) : d2.diff(d1);
    });
    return sorted;
};

export const categoryAggregated = (transactions) => {
    if (!transactions) {
        return {};
    }

    const saleTransactions = transactions.filter((t) => ALLOWED_TRANSACTION_TYPES.indexOf(t.transaction_type) !== -1);

    const categorized = saleTransactions.reduce((aggregates, t) => {
        const key = `${t.transaction_category ? t.transaction_category.name : "NONE"}`;
        const color = `${t.transaction_category ? t.transaction_category.color : "#000000"}`;
        aggregates[key] = { amount: +(((aggregates[key] || {}).amount || 0) + t.amount).toFixed(2), category: key, color: color };
        return aggregates;
    }, {});
    return Object.values(categorized);
};

export const getTopFiveCategories = (data = []) => {
    if (data.length == 0) return [];
    
    const sorted = data.sort((a,b) => {
      if (a.amount < b.amount) {
        return 1;
      } else if (b.amount < a.amount) {
        return -1;
      }
      return 0;
    }).map(t => t.category);
  
    return sorted.slice(0, 5);
};

export const getCategorizedForEachMonth = (transactions) => {
    if (!transactions) {
      return {};
    }
  
    const saleTransactions = transactions.filter((t) => ALLOWED_TRANSACTION_TYPES.indexOf(t.transaction_type) !== -1);
  
    const data = saleTransactions.reduce((accum, t) => {
      const month = moment(t.transaction_date, SIMPLE_DATE_FORMAT).month();
      const monthName = months[month];
      if (!accum[monthName]) {
          accum[monthName] = {};
      }
      accum[monthName][t.transaction_category.name] = +(((accum[monthName] || {})[t.transaction_category.name] || 0) + t.amount).toFixed(2);
      accum[monthName] = { ...accum[monthName], color: t.transaction_category.color, month: monthName };
  
      return accum;
    }, {});
  
    const uniqueCategories = [...new Map(saleTransactions.map((item) => [item.transaction_category.name, item.transaction_category])).values()];
  
    return {data: data, categories: uniqueCategories};
};

export const calculateDashboardPayload = (transactions) => {
    const beginningOfYear = moment().startOf('year');
    const beginningOfPastYear = moment().startOf('year').subtract(1, 'years');
    const sorted = sort(transactions, true);
    const transactionFromPastYear = sorted && sorted.length > 0 && sorted.filter(t => moment(t.transaction_date, SIMPLE_DATE_FORMAT).isSameOrAfter(beginningOfPastYear) && moment(t.transaction_date, SIMPLE_DATE_FORMAT).isBefore(beginningOfYear));
    const transactionsFromCurrentYear = sorted && sorted.length > 0 && sorted.filter(t => moment(t.transaction_date, SIMPLE_DATE_FORMAT).isSameOrAfter(beginningOfYear));
    // payload
    const monthlyAggregated = getMonthlyAggregated(transactionFromPastYear, transactionsFromCurrentYear);
    // payload
    const aggregatedByCategory = categoryAggregated(transactionFromPastYear) || [];
    // payload
    const categorizedByMonthLastYear = getCategorizedForEachMonth(transactionFromPastYear) || [];
    // payload
    const categorizedByMonthCurrentYear = getCategorizedForEachMonth(transactionsFromCurrentYear) || [];
    // payload
    const top5Categories = getTopFiveCategories(aggregatedByCategory);

    const aggregatedForTop5Categories = {};
    (transactionFromPastYear || []).forEach(t => {
        if (t.transaction_category && top5Categories.indexOf(t.transaction_category.name) !== -1) {
            if (t.transaction_category.name in aggregatedForTop5Categories) {
                aggregatedForTop5Categories[t.transaction_category.name].push(t);
            } else {
                aggregatedForTop5Categories[t.transaction_category.name] = [t];
            }
        }
    });

    const simplifiedForCategories = {};
    Object.keys(aggregatedForTop5Categories).forEach(key => {
        const itemList = aggregatedForTop5Categories[key];
        const resolvedNameMap = itemList.reduce((accum, curr) => {
            const nameKey = ((curr || {}).description || "").split(' ')[0];
            const prevAmount = accum[nameKey] ? accum[nameKey].amount || 0 : 0;
            const prevName = accum[nameKey] ? accum[nameKey].description : curr.description;
            accum[nameKey] = { ...curr, description: prevName, amount: +(prevAmount + curr.amount).toFixed(2), simple_datetime: moment(curr.transaction_date).format(SIMPLE_DATE_FORMAT) };
            return accum;
        }, {});
        const category = itemList[0].transaction_category.name;
        simplifiedForCategories[category] = Object.values(resolvedNameMap).sort((a,b) => {
            return b.amount - a.amount;
        });
    });

    return {
        monthlyAggregated,
        aggregatedByCategory,
        categorizedByMonthLastYear,
        categorizedByMonthCurrentYear,
        transactionFromPastYear,
        top5Categories,
        simplifiedForCategories,
    };
};